<template>
    <div class="zhongbiao">
        <A4 :opt="opt" :size="18">
            <template v-slot:content>
                <div class="flex wrap">
                    <div class="w-2 flex" v-for="(value,key) in opt.data" :key="key"
                         :style="{'width':100/opt.timuLie+'%'}">
                            <span v-show="opt.serialNumber" class="serialNumber">{{
                                    String(key + 1).padStart(2, '0')
                                }}</span>
                        <div class="timu" :style="{'margin-bottom': opt.timuLineHeight+'em'}">
                            <clock :time="value" :timuSize="opt.timuSize" :showTimeText="opt.showTimeText" :showPointer="opt.showPointer" :showNumber="opt.showNumber"></clock>
                        </div>
                    </div>
                </div>
            </template>
        </A4>
    </div>
</template>

<script>

import mounted from '@/mix/mounted'
import clock from "@/components/clock.vue";
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    components: {clock},
    name: "zhongbiao",
    mixins: [mounted], // 使用 mixin
    data() {
        return {
            opt: {},
        }
    },
    methods:{
        replaceSymbol(value) {

        },
    },
    created(){
        let dataStr = "{\"data\":[{\"hour\":3,\"min\":\"33\"},{\"hour\":4,\"min\":\"23\"},{\"hour\":1,\"min\":\"25\"},{\"hour\":7,\"min\":\"13\"},{\"hour\":4,\"min\":\"57\"},{\"hour\":8,\"min\":\"16\"},{\"hour\":4,\"min\":\"38\"},{\"hour\":8,\"min\":\"05\"},{\"hour\":5,\"min\":\"41\"},{\"hour\":9,\"min\":\"16\"},{\"hour\":7,\"min\":\"49\"},{\"hour\":11,\"min\":\"16\"},{\"hour\":6,\"min\":\"10\"},{\"hour\":9,\"min\":\"52\"},{\"hour\":11,\"min\":\"51\"},{\"hour\":9,\"min\":\"12\"},{\"hour\":1,\"min\":\"58\"},{\"hour\":11,\"min\":\"21\"},{\"hour\":4,\"min\":\"10\"},{\"hour\":10,\"min\":\"43\"}],\"type\":\"zhongbiao\",\"limit\":10,\"ischuti\":false,\"isbuchong\":false,\"chuti\":\"一行一个。时间用 # 分隔开，只支持到分。例如：\\n8#04\\n3#30\\n11#00\",\"showInfo\":[\"姓名\",\"日期\",\"用时\",\"得分\"],\"title\":\"\",\"defaultTitle\":\"认识钟表\",\"timuLie\":4,\"timuNumber\":20,\"timuLineHeight\":1.8,\"timuSize\":80,\"serialNumber\":false,\"daan\":false}"
        let data = JSON.parse(dataStr)
        this.opt = data
        console.log(data)
    },
    mounted(){

    }
}
</script>

<style lang="scss">


</style>
