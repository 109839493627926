<template>
    <div class="fenhe">
        <A4 :opt="opt">
            <template v-slot:content>
                <div class="flex wrap">
                    <div class="w-2 flex" v-for="(value,key) in opt.data" :key="key"
                         :style="{'width':100/opt.timuLie+'%'}">
                        <span v-if="opt.serialNumber" class="serialNumber">{{key + 1 }}</span>
                        <div class="timu" :style="{'margin-bottom': opt.timuLineHeight+'em'}">
                            <div :class=" value.n==='c'?'shuzi result':'shuzi' ">{{ replaceSymbol(value, 'c') }}</div>
                            <div class="xian">
                                <div class="pie"></div>
                                <div class="na"></div>
                            </div>
                            <div class="flex">
                                <div :class=" value.n==='l'?'shuzi result':'shuzi' ">{{ replaceSymbol(value, 'l') }}
                                </div>
                                <div :class=" value.n==='r'?'shuzi result':'shuzi' ">{{ replaceSymbol(value, 'r') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </A4>
    </div>
</template>

<script>

import mounted from '@/mix/mounted'
export default {
    name: "fenhe",
    mixins: [mounted], // 使用 mixin
    data() {
        return {
            opt: {},
        }
    },
    methods:{
        replaceSymbol(value, prop) {
            if (this.opt.daan) return value[prop];
            if (value.n === prop) return ''
            return value[prop]
        },
    },
    created(){},
    mounted(){

    }
}
</script>

<style lang="scss">


</style>
