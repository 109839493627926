<template>
    <div class="container">
        <div class="A4-box">
            <div class="A4 flex column" id="A4" ref="A4">
                <div class="head">
                    <h2>{{ opt.title ||opt.defaultTitle }}</h2>
                    <div class="info">
                        <div v-for="(value,key) in opt.showInfo" :key="key" class="info-item">
                            <span>{{ value }}：</span>
                            <!--<span class="borderBottom" :style="{ width: 100/checkInfoList.length + '%' }"></span>-->
                            <span class="borderBottom"></span>
                        </div>
                    </div>
                </div>
                <div class="content" :style="{'font-size':(size||opt.timuSize)+'px'}">
                    <slot name="content"></slot>
                </div>
                <div class="copyright">微信小程序@练习题生成</div>
            </div>
        </div>
    </div>
</template>

<script>
// import CryptoJS from 'crypto-js';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'A4',
    data() {
        return {

        }
    },
    props: {
        opt: {
            type: Object, // 指定数据类型
            required: true // 设置为必需
        },
        // 如果指定size,就使用size大小，而不使用opt.timuSize
        size: {
            type: Number
        },

    },
    methods: {
    }
};
</script>

<style scoped lang="scss">
/**/
.A4-box{
    box-shadow: none;
    animation: clearBlur 8s forwards; /* 使用动画实现模糊到清晰效果，时长为5秒 */
}

@keyframes clearBlur {
    from {
        filter: blur(5px); /* 开始时模糊半径为5像素 */
    }
    to {
        filter: blur(0px); /* 结束时模糊半径为0像素 */
    }
}



</style>