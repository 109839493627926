import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import daxiao from '../views/daxiao.vue'
import fenhe from '../views/fenhe.vue'
import zhongbiao from '../views/zhongbiao.vue'
import yunsuan from '../views/yunsuan.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: index
  },
  {
    path: '/daxiao',
    component: daxiao
  },
  {
    path: '/fenhe',
    component: fenhe
  },
  {
    path: '/zhongbiao',
    component: zhongbiao
  },
  {
    path: '/yunsuan',
    component: yunsuan
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
