export default {
    mounted() {
        const query = this.$route.query;
        if (query.render) {
            let render = decodeURIComponent(query.render)
            try{
                this.opt = JSON.parse(render)
                setTimeout(()=>{
                    this.$img()
                },0)
            }catch (e) {}
        }

        // 其他需要执行的代码
    }
};