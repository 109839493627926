<template>
    <div>

    </div>
</template>

<script>


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "index",
    components: {}
}
</script>

<style lang="scss">


</style>
