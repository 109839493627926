<template>
    <div class='clock-box' :style="{'font-size':timuSize+'px'}">
        <div class='clock'>
            <div class='num-box-box'>
                <div class="num-box  f-kai" v-if="showNumber" v-for="(value,key) in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="key"
                     :style="{transform:'rotate('+ value * 30 +'deg)'}"
                >
                    <span class="num" :style="{transform:'rotate(' + value * -30 + 'deg)'}">{{ value }}</span>
                </div>
            </div>
            <div class="line-box-box">
                <div class="line-box" v-for="(value, key) in Array.from({ length: 60 }, (_, index) => index + 1)"
                     :key="key"
                     :style="{ transform: 'rotate(' + value * 6 + 'deg)' }">
                    <div :class="value % 5 === 0?'line longLine':'line'"></div>
                </div>
            </div>
            <div class="pointer hour" v-if="showPointer"
                 :style="{ transform: 'translateY(-0.1em) rotate(' + (time.min/60+time.hour) * 30 + 'deg)' }"></div>
            <div class="pointer min" v-if="showPointer"
                 :style="{ transform: 'translateY(-0.15em) rotate(' + time.min * 6 + 'deg)' }"></div>
            <!--<div class="pointer sec" v-if="showPointer" :style="{ transform: 'translateY(-0.2em) rotate(' + time.sec * 6 + 'deg)' }"></div>-->
        </div>
        <div class="time" >
            <div v-if="showTimeText">
                <span v-if="time.hour">{{ time.hour }}</span>
                <span v-if="time.min">:{{ time.min }}</span>
                <span v-if="time.sec">:{{ time.sec }}</span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "Clock",
    data() {
        return {

        }
    },
    props: {
        time: {     //传入时间 8:30:05 或 8:30
            type: Object, // 指定数据类型
            required: true // 设置为必需
        },
        timuSize: {    //钟表大小
            type: Number,
            default: 100,
        },
        showTimeText:Boolean,
        showPointer:Boolean,
        showNumber:Boolean,
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">

</style>