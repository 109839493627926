import Vue from 'vue'
import App from './App.vue'
import router from './router'


import A4 from "./components/A4.vue"
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("A4", A4)


// 将指定html内容生成图片 https://gitcode.com/bubkoo/html-to-image/overview
import * as htmlToImage from 'html-to-image';
Vue.prototype.$img = function (query="#A4"){
    let node = document.querySelector(query)
    let returnData = '' //返回给微信小程序的数据

    htmlToImage.toBlob(node, { pixelRatio: 3 })
    .then(blob => {
        const formData = new FormData();
        formData.append('file', blob, '.png');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://lianxiti.jjtz.cc/get_img.php',false);
        xhr.send(formData);
        if (xhr.status === 200) {
            returnData = encodeURIComponent(xhr.responseText)
        } else {
            returnData = encodeURIComponent(JSON.stringify({
                code:404,
                msg:'错误代码：404。可能是暂时的网络错误，您可以返回再试一下',
                error:xhr.status
            }))
        }
    })
    .catch(error => {
        returnData = encodeURIComponent(JSON.stringify({
            code:405,
            msg:'错误代码：405',
            error:error
        }))
    })
    .finally(() => {
        console.log(returnData)
        wx.miniProgram.redirectTo({ url: `/pages/render/render?data=${returnData}` })
    });



    // 封装获取图片数据的函数
    // function getImageData() {
    //     return new Promise((resolve, reject) => {
    //         htmlToImage.toBlob(node, { pixelRatio: 3 })
    //         .then(blob => {
    //             const formData = new FormData();
    //             formData.append('file', blob, '.png');
    //             fetch('http://lianxiti.jjtz.cc/get_img.php', {
    //                 method: 'POST',
    //                 body: formData
    //             })
    //             .then(response => response.text())
    //             .then(data => {
    //                 console.log(JSON.parse(data))
    //                 returnData = encodeURIComponent(data)
    //                 resolve(returnData); // 将获取到的数据传递给下一个 .then()
    //             })
    //             .catch(error => {
    //                 reject(error); // 发生错误时，将错误信息传递给下一个 .catch()
    //             });
    //         })
    //         .catch(error => {
    //             reject(error); // 发生错误时，将错误信息传递给下一个 .catch()
    //         });
    //     });
    // }
    //
    // // 执行异步操作，并在 finally 中执行跳转操作
    // getImageData()
    // .catch((error)=>{
    //     returnData='3'+error.toString()
    // })
    // .finally(() => {
    //     console.log('永远执行')
    //     console.log(returnData)
    //     wx.miniProgram.redirectTo({ url: `/pages/render/render?data=${returnData}` })
    // });

}

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
