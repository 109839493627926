<template>
    <div class="yunsuan">
        <A4 :opt="opt">
            <template v-slot:content>
                <div class="flex wrap">
                    <div class="w-2 flex wrap items-center pre" v-for="(item,index) in opt.data" :style="'width:'+100/opt.timuLie+ ';'+'line-height:'+opt.timuLineHeight">
                        <span v-if="opt.serialNumber" class="serialNumber">{{index+1}}</span>
                        <div class="timu flex">
                            <div v-for="(value,vIndex) in item.left" :class="(value-0)!=value?'symbol':''">{{value}}</div>
                            <div class="symbol">=</div>
                            <div class="result">{{opt.daan?item.right:''}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </A4>
    </div>
</template>

<script>

import mounted from '@/mix/mounted'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "yunsuan",
    mixins: [mounted], // 使用 mixin
    data() {
        return {
            opt: {},
        }
    },
    methods:{
        replaceSymbol(value) {

        },
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style lang="scss">


</style>
